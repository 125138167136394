<template>
  <div id="game-history" class="mt-2">

    <div class="p-2">
      <h6 class="mb-1">รายการเล่นเกม</h6>
      <DateFilter @submit="search" />
    </div>

    <div class="position-relative">
      <div class="p-2">
        <TxnTable
          :data="data"
          :perPage="perPage"
          :currentPage="currentPage"
          :isLoading="isLoading"
          :isLoaded="isLoaded"
          @page="togglePage"
        />
      </div>

      <loading
        :active="isLoading"
        :can-cancel="false"
        :is-full-page="false"
        background-color="#EBEDEF"
        :height="60"
        :width="60"
        color="#CC3636"
        loader="dots"
      />
    </div>
  </div>
</template>
<script>
import DateFilter from '@/views/report/components/DateFilter'
import moment from '@/helpers/moment'
import GameService from '@/services/GameService'
import cAlert from '@/helpers/alert'
import TxnTable from './components/TxnTable'

export default {
  name: 'GameHistory',
  components: {
    DateFilter,
    TxnTable
  },
  data() {
    return {
      isLoading: false,
      isLoaded: false,
      filters: {
        startDate: null,
        endDate: null
      },
      data: null,
      perPage: 100,
      currentPage: 1
    }
  },
  computed: {
    offset() {
      return (this.currentPage * this.perPage) - this.perPage
    }
  },
  methods: {
    search(filters) {
      this.currentPage = 1
      this.filters = {
        startDate: filters.date.start,
        endDate: filters.date.end,
      }
      this.loadData()
    },
    loadData() {
      this.isLoading = true
      GameService.getHistories(this.filters, this.perPage, this.offset).then((response)=>{
        if(response.success) {
          this.isLoaded = true
          this.data = response.data
        }else{
          throw new Error(response?.message)
        }
      })
      .catch((e)=>{
        cAlert({
          title: 'ผิดพลาด!',
          text: 'โหลดประวัติการเล่นไม่สำเร็จ',
          icon: 'error',
          confirmButtonText: 'OK'
        })
      })
      .finally(()=>{
        this.isLoading = false
      })
    },
    togglePage(page) {
      this.currentPage = page
      this.loadData()
    }
  }
}
</script>
